import React, { Component } from 'react';
import './index.css';
import NavBar from '../NavBar';
import variables from '../../utils/variables';
import * as PropTypes from 'prop-types';
import TokenDetails from './TokenDetails';
import DelegateDialog from '../Stake/DelegateDialog';
import SuccessDialog from '../Stake/DelegateDialog/SuccessDialog';
import UnSuccessDialog from '../Stake/DelegateDialog/UnSuccessDialog';
import ClaimDialog from './ClaimDialog';
import Table from '../Stake/Table';
import { Button } from '@material-ui/core';
import Cards from '../Proposals/Cards';
import ProposalDialog from '../Proposals/ProposalDialog';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

class Home extends Component {
    constructor (props) {
        super(props);

        this.state = {
            active: 1,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
    }

    componentDidMount () {
        if ((this.props.address !== '') && (this.state.active !== 2)) {
            this.setState({
                active: 2,
            });
        }
    }

    componentDidUpdate (pp, ps, ss) {
        if ((pp.address !== this.props.address) &&
            (this.props.address !== '') && (this.state.active !== 2)) {
            this.setState({
                active: 2,
            });
        }
        if ((pp.address !== this.props.address) &&
            (this.props.address === '') && (this.state.active !== 1)) {
            this.setState({
                active: 1,
            });
        }
    }

    handleChange (value) {
        if (this.state.active === value) {
            return;
        }

        this.setState({
            active: value,
        });
    }

    handleRedirect (value) {
        this.props.history.push(value);
    }

    render () {
        const { active } = this.state;
        const filteredProposals = this.props.proposals && this.props.proposals.filter((item) => item.status === 2);

        return (
            <>
                <NavBar/>
                <div className="home padding">
<marquee>Welcome Cosmos and EVM communities!</marquee>
                    <div className="card">
                        <div className="left_content">
                            <h2>Hello and thank you for your interest!</h2>
                            <p className="info"> Web page only to VIEW proposals and add GenesisL1 blockchain, best to have own node for that! You can interact with GenesisL1 blockchain @ ping.pub: </p> <pre>https://ping.pub/genesisL1</pre> <p className="info"> GenesisL1 is a Science (desci) and Art experimental project:</p> <pre>https://GenesisL1.com</pre><p className="info"> In anything related to GenesisL1 there is  NO WARRANTY OF ANY KIND, PROVIDED AS IS, USE AT YOUR OWN RISK AND WILL! </p>
                        </div>
                        <TokenDetails lang={this.props.lang}/>
                    </div>
                </div>
                <div className="stake">
                    <div className="stake_content padding">
                        <div className="heading">
                            <div className="tabs">
                                <p className={active === 2 ? 'active' : ''} onClick={() => this.handleChange(2)}>
                                    {variables[this.props.lang]['staked_validators']}
                                </p>
                                <span/>
                                <p className={active === 1 ? 'active' : ''} onClick={() => this.handleChange(1)}>
                                    {variables[this.props.lang]['all_validators']}
                                </p>
                            </div>
                            <Button className="view_all" onClick={() => this.handleRedirect('/stake')}>
                                {variables[this.props.lang]['view_all']}
                            </Button>
                        </div>
                        <Table active={active} home={true}/>
                    </div>
                </div>
                <div className="proposals">
                    {!this.props.open
                        ? <div className="proposals_content padding">
                            <div className="heading">
                                <div className="tabs">
                                    <p className="active">
                                        {variables[this.props.lang]['top_active_proposals']}
                                    </p>
                                </div>
                                <Button className="view_all" onClick={() => this.handleRedirect('/proposals')}>
                                    {variables[this.props.lang]['view_all']}
                                </Button>
                            </div>
                            {this.props.proposalsInProgress || this.props.voteDetailsInProgress
                                ? <div className="cards_content">Loading...</div>
                                : filteredProposals && filteredProposals.length
                                    ? <Cards home={true} proposals={filteredProposals}/>
                                    : <div className="cards_content">{variables[this.props.lang]['no_data_found']}</div>}
                        </div>
                        : <ProposalDialog/>}
                </div>
                <DelegateDialog/>
                <SuccessDialog/>
                <UnSuccessDialog/>
                <ClaimDialog/>
            </>
        );
    }
}

Home.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    proposals: PropTypes.array.isRequired,
    voteDetailsInProgress: PropTypes.bool.isRequired,
    address: PropTypes.string,
    proposalsInProgress: PropTypes.bool,
};

const stateToProps = (state) => {
    return {
        address: state.accounts.address.value,
        lang: state.language,
        open: state.proposals.dialog.open,
        proposals: state.proposals._.list,
        proposalsInProgress: state.proposals._.inProgress,
        voteDetailsInProgress: state.proposals.voteDetails.inProgress,
    };
};

export default withRouter(connect(stateToProps)(Home));
