export const config = {
    RPC_URL: 'https://26657.genesisl1.org',
    REST_URL: 'https://1317.genesisl1.org',
    EXPLORER_URL: 'https://ping.pub/genesisL1',
    NETWORK_NAME: 'GenesisL1',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'genesis_29-2',
    CHAIN_NAME: 'GenesisL1',
    COIN_DENOM: 'L1',
    COIN_MINIMAL_DENOM: 'el1',
    COIN_DECIMALS: 18,
    PREFIX: 'genesis',
    COIN_TYPE: 118,
    COINGECKO_ID: 'genesisl1',
    DEFAULT_GAS: 51000000000,
    GAS_PRICE_STEP_LOW: 51000000000,
    GAS_PRICE_STEP_AVERAGE: 52000000000,
    GAS_PRICE_STEP_HIGH: 53000000000,
    FEATURES: ['stargate'],
};

